import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import HomePage from "./components/HomePage";
import $ from "jquery";
import ProductPage from "./components/ProductPage";
import SearchPage from "./components/SearchPage";
import BranchPage from "./components/BranchPage";
import DeliveryTimePage from "./components/DeliveryTimePage";
import ScrollToTop from "./components/Common/ScrollToTop";
import BranchListPage from "./components/BranchListPage";
import { BarLoader } from "react-spinners";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import AppInstall from "./components/Common/AppInstall";
import ReactPixel from "react-facebook-pixel";
import SnapPixel from "react-snapchat-pixel";
import TiktokPixel from "tiktok-pixel";
import { googleinit } from "./gtag.js";
import Booking from "./components/Booking";
import BookingConfirm from "./components/BookingConfirm";
import NotAllowed from "./components/NotAllowed";
import { subscribe } from "./api";
import TrackOrder from "./components/TrackOrder";
import NewCheckOutPage from "./components/NewCheckOutPage";
import NewDeliveryAddress from "./components/NewDeliveryAddress";
import NewOrderDetailsPage from "./components/NewOrderDetailsPage";
import NewOrderStatuPage from "./components/NewOrderStatusPage";
import NewReviewOrderPage from "./components/NewReviewOrderPage";
import NewOrderMode from "./components/NewOrderMode";
import LayoutHolder from "./components/LayoutHolder";
import NewCategoeyModal from "./components/NewCategoryModal";
import {
  emptyUserCart,
  getAllCategoryAndProducts,
  getBookingBranches,
  getBookingHomepage,
  getUserCart,
  getUserLocation,
  getVendorSlug,
} from "./components/APIS/landingPageAPI.js";
import { setPageView } from "./components/APIS/facebookApi.js";
import {
  getDeliveryPickupList,
  getEstorebraches,
} from "./components/APIS/areaAndTimesAPi.js";
import packageJson from "../package.json";
import axios from "axios";
import { API_URL } from "./services/constants.js";
// import ApplePay from "./ApplePay.jsx";

export const LanguageContext = React.createContext();
export const VendorContext = React.createContext();
export const VendorSlugContext = React.createContext();
export const AreaContext = React.createContext();
export const AvaibilityContext = React.createContext();
export const CartContext = React.createContext();
export const ContactContext = React.createContext();
export const SearchContext = React.createContext();
export const BookingSetContext = React.createContext();
export const UserContext = React.createContext();
export const InterNationalContext = React.createContext();

let deferredPrompt;

const allowedCountries = ["BH", "KW", "OM", "QA", "SA", "AE", "UK", "US", "IN"];

/**
 * @desc This contains the logic for whether Captcha component or the main app needs to be displayed
 */
const buildDateGreaterThan = (latestDate, currentDate) => {
  const momLatestDateTime = moment(latestDate);
  const momCurrentDateTime = moment(currentDate);

  return !!momLatestDateTime.isAfter(momCurrentDateTime);
};

function App() {
  const [isCountryBlocked, setIsCountryBlocked] = useState(1);
  const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

  /**
   * @desc This gets the user's country using their ip and checks if the country is present in allowedCountries{Array}
   */
  const getGeoInfo = async () => {
    try {
      const response = await getUserLocation();
      return allowedCountries.includes(response.country_code);
    } catch (error) {
      return false;
    }
  };

  /**
   * @desc useEffect for calling getGeoInfo function and setting if the user requires captcha to access the page
   */
  useEffect(async () => {
    const isCountryBlocked = await getGeoInfo();
    setIsCountryBlocked(isCountryBlocked ? 2 : 3);
  }, []);

  useEffect(() => {
    fetch("/meta.json")
      .then((response) => response.json())
      .then((meta) => {
        const latestVersionDate = meta.buildDate;
        const currentVersionDate = packageJson.buildDate;

        const shouldForceRefresh = buildDateGreaterThan(
          latestVersionDate,
          currentVersionDate
        );
        if (shouldForceRefresh) {
          setIsLatestBuildDate(false);
          refreshCacheAndReload();
        } else {
          setIsLatestBuildDate(true);
        }
      });
  }, []);

  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    // delete browser cache and hard reload
    window.location.reload();
    // window.location = self.location;
  };

  return !(isCountryBlocked === 1) ? (
    isCountryBlocked === 2 ? (
      <AppComponent></AppComponent>
    ) : (
      <NotAllowed setIsCountryBlocked={setIsCountryBlocked} />
    )
  ) : null;
}

function AppComponent() {
  const [vendorSlug, setVendorSlug] = useState("");
  const [avaibility, setAvaibility] = useState({});
  const [search, setSearch] = useState("");
  const [language, setLanguage] = useState(
    sessionStorage.getItem("language")
      ? sessionStorage.getItem("language")
      : "ltr"
  );
  const [loading, setLoading] = useState(true);
  const [cart, setCart] = useState({});
  const [addressDetails, setAddressDetails] = useState({
    block: "",
    street: "",
    avenue: "",
    house: "",
    floor: "",
    flat: "",
    special_directions: "",
    lat: 29.378,
    lng: 47.99,
    fixedLat: 29.378,
    fixedLng: 47.99,
    addressString: "",
    addressType: "1",
  });
  const [contactDetails, setContactDetails] = useState({
    name: "",
    phoneCode: "KW",
    phone: "",
    email: "",
    model: "",
    color: "",
    license: "",
  });
  const [payment, setPayment] = useState(1);
  const [areaDetails, setAreaDetails] = useState({
    type:
      window.location.host.replace(/^www\./, "") !== "shop.playon.today"
        ? "delivery"
        : "pickup",
    data: {},
    area: "",
    branch: "",
    branch_id: "",
    area_id: "",
    branchForArea: {},
    deliveryTiming: "",
    pickupTiming: "",
    customDelivery: false,
    getDeliveryTiming: moment().add(2, "hours").toDate(),
    laterDeliveryTiming: moment().add(2, "hours").toDate(),
    laterPickupTiming: moment().add(2, "hours").toDate(),
    now: 1,
    ar_area: "",
    ar_branch: "",
    ar_deliveryTiming: "",
    ar_pickupTiming: "",
    shopOpen: 1,
    minimum: "",
    branch_lat: "",
    branch_lng: "",
  });
  const [toggleView, setToggleView] = useState(1);

  const [installable, setInstallable] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [noVendor, setNoVendor] = useState(true);

  const [bookingSets, setBookingSets] = useState({
    max_date_selection: "30",
    places: [],
    dateNow: moment()?.locale("en"),
    max_slot: 9999,
    is_multi_slot: 1,
    morning: [],
    evening: [],
    uniqueMorning: [],
    uniqueEvening: [],
    week_days: [
      { name: "Sunday", name_ar: "الأحد", id: 7 },
      { name: "Monday", name_ar: "الاثنين", id: 1 },
      { name: "Tuesday", name_ar: "الثلاثاء", id: 2 },
      { name: "Wednesday", name_ar: "الأربعاء", id: 3 },
      { name: "Thursday", name_ar: "الخميس", id: 4 },
      { name: "Friday", name_ar: "الجمعة", id: 5 },
      { name: "Saturday", name_ar: "السبت", id: 6 },
    ],
    booking_month: [],
    booking_search: false,
    cronBooked: [],
    base: [],
    baseTime: [],
    baseVendor: [],
    selectedPlace: {},
    placeClicked: false,
  });
  const [userDetails, setUserDetails] = useState({
    id: "",
    email: "",
    firstname: "",
    lastname: "",
    firstname_ar: "",
    lastname_ar: "",
    mobilenumber: "",
    country_code: "",
    is_guest: true,
    is_social: false,
  });
  const [internationalDelivery, setInternationalDelivery] = useState({
    country_name: "Kuwait",
    country_code: "KW",
    currency: "",
    currency_name: "",
    is_interNational: 0,
    city: "",
    region: "",
    delivery_country: "Kuwait",
    delivery_country_code: "KW",
    delivery_state: "",
    delivery_state_code: "",
    delivery_city: "",
    delivery_city_code: "",
    delivery_address1: "",
    delivery_address2: "",
    delivery_zipCode: "",
    delivery_specialInstruction: "",
    country_id: "1",
    delivery_expected_time: "",
    delivery_expected_type: "",
  });

  const getUserCountry = async () => {
    try {
      const response = await getUserLocation();
      if (response) {
        if (response.country_name.toLowerCase() !== "kuwait") {
          setInternationalDelivery({
            ...internationalDelivery,
            country_name: response.country_name,
            country_code: response.country_code,
            city: response.city,
            region: response.region,
            delivery_country: response.country_name,
            delivery_country_code: response.country_code,
            currency: response.currency,
            currency_name: response.currency_name,
            is_interNational: 1,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * @desc lightOrDark function takes vendor color value and returns true if the color is dark else false if the color is light
   * @param {String}color
   * @returns {Boolean}
   */
  function lightOrDark(color) {
    let r, g, b, hsp;
    if (color.match(/^rgb/)) {
      color = color.match(
        /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
      );
      r = color[1];
      g = color[2];
      b = color[3];
    } else {
      color = +(
        "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
      );
      r = color >> 16;
      g = (color >> 8) & 255;
      b = color & 255;
    }
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
    if (hsp > 127.5) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * @desc LightenDarkenColor function takes hex color value and amount of value to be added, and returns
   *       a hex color value having the desired lightness or darkness. This function is used to get the hover color from
   *       the vendor color.
   * @param {String}col, {Integer}amt
   * @returns {String}
   */
  function LightenDarkenColor(col, amt) {
    var usePound = false;
    if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
    }
    var num = parseInt(col, 16);
    var r = (num >> 16) + amt;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    var b = ((num >> 8) & 0x00ff) + amt;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
    var g = (num & 0x0000ff) + amt;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  }

  /**
   * @desc useEffect to check if the PWA app is installable and to set the install button design
   *       according to the user device
   *
   */
  useEffect(() => {
    let chromeAgent = navigator.userAgent.indexOf("Chrome") > -1;
    let safariAgent = navigator.userAgent.indexOf("Safari") > -1;
    const isInStandaloneMode = () =>
      "standalone" in window.navigator && window.navigator.standalone;
    if (chromeAgent && safariAgent) safariAgent = false;
    if (!safariAgent) {
      window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();
        deferredPrompt = e;
        setInstallable(true);
      });
      window.addEventListener("appinstalled", () => {
        console.log("INSTALL: Success");
      });
    } else {
      if (!isInStandaloneMode()) setInstallable(true);
      else setInstallable(false);
    }
  }, []);

  /**
   * @desc handles the PWA app install functionality
   * @param {Object}e
   */
  const handleInstallClick = (e) => {
    setInstallable(false);
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the install prompt");
      } else {
        console.log("User dismissed the install prompt");
      }
    });
  };

  /**
   * @desc This function contains the axios calls to get the delivery area details and branches for the estore section
   *       and sets the data in areaDetails state
   */

  const loadArea = async () => {
    const deliveryResponse = await getDeliveryPickupList({
      vendors_id: details?.vendor?.vendors_id,
      vendorSlug: vendorSlug,
    });
    if (deliveryResponse?.status) {
      setAreaDetails((areaDetails) => ({
        ...areaDetails,
        data: { ...areaDetails.data, ...deliveryResponse.data },
      }));
    }
    const estoreBranchesResponse = await getEstorebraches({
      vendors_id: details?.vendor?.vendors_id,
      vendorSlug: vendorSlug,
    });
    if (estoreBranchesResponse?.status) {
      if (
        estoreBranchesResponse.data ||
        estoreBranchesResponse.data?.length != 0
      ) {
        // map to check if the store is open for 24hrs or not and changing the values of start and end time to show 24hrs
        let b = estoreBranchesResponse.data.map((l, q) => {
          let diff = Math.abs(
            moment(l.office_start_time, "HH:mm:ss").diff(
              moment(l.office_end_time, "HH:mm:ss"),
              "minutes"
            )
          );
          return {
            ...l,
            office_end_time:
              diff == 1 || diff == 1439 ? "23:59:59" : l.office_end_time,
            office_start_time:
              diff == 1 || diff == 1439 ? "00:00:00" : l.office_start_time,
          };
        });
        let autoBranch = {};
        if (
          estoreBranchesResponse.data.length == 1 &&
          vendorSlug === "alawael-bilingual-school"
        ) {
          const value = await getBranch(
            estoreBranchesResponse.data[0]?.name,
            estoreBranchesResponse.data[0]?.arabic_name,
            estoreBranchesResponse.data[0]?.id,
            estoreBranchesResponse.data[0]?.area_ids[0] ?? 1,
            estoreBranchesResponse
          );
          autoBranch = value;
        }
        setAreaDetails((areaDetails) => ({
          ...areaDetails,
          data: { ...areaDetails.data, branch: b },
          ...autoBranch,
        }));
      }
    }
  };

  const getBranch = async (eng, arab, key, area, branch) => {
    const changeAreaApi = await axios.post(
      `${API_URL}/change-area`,
      JSON.stringify({
        token: process.env.REACT_APP_TOKEN,
        vendor_id: details?.vendor?.vendors_id,
        area_id: area,
        vendor_slug: vendorSlug,
        user_string: localStorage.getItem("userID"),
      })
    );
    if (changeAreaApi.data.status) {
      let temp = changeAreaApi.data.data;
      if (temp?.show_popup == 0) {
        let temp = branch?.data?.filter((k, i) => k?.id == key);
        temp = temp[0];
        let start, end;
        if (temp?.office_end_time == "00:00:00") {
          start = new Date(
            new Date().setHours(...temp?.office_start_time?.split(":"))
          );
          end = new Date(new Date().setHours(23, 59, 59));
        } else {
          start = new Date(
            new Date().setHours(...temp?.office_start_time?.split(":"))
          );
          const dummyEnd = new Date(
            new Date().setHours(...temp?.office_end_time?.split(":"))
          );
          end =
            vendorSlug !== "alawael-bilingual-school"
              ? dummyEnd
              : new Date(dummyEnd.setMinutes(dummyEnd.getMinutes() - 30));
        }

        let current = new Date();
        if (vendorSlug !== "alawael-bilingual-school") {
          current.setHours(current.getHours() + 2);
        }
        const dummyStart = new Date(start);
        if (!(start > current || current > end)) {
          const res = {
            branch: eng,
            minimum: "",
            area_id: area,
            shopOpen: 1,
            now: 1,
            area: "",
            ar_area: "",
            ar_branch: arab,
            branch_id: key,
            deliveryTiming: getWithinValue(end),
            ar_deliveryTiming: getWithinValue(end, true),
            getDeliveryTiming: moment().add(120, "minutes").toDate(),
            laterDeliveryTiming: moment().add(120, "minutes").toDate(),
            branchForArea: {
              id: key,
              english: eng,
              arabic: arab,
              end:
                temp?.office_end_time == "00:00:00"
                  ? moment("23:59:59", "HH:mm:ss")
                  : moment(temp?.office_end_time, "HH:mm:ss"),
              start: moment(temp?.office_start_time, "HH:mm:ss"),
            },
          };
          return res;
        } else {
          const res = {
            branch: eng,
            minimum: "",
            area_id: area,
            shopOpen: 2,
            now: 2,
            ar_branch: arab,
            branch_id: key,
            area: "",
            ar_area: "",
            deliveryTiming: "24 Hours",
            ar_deliveryTiming: "24 ساعات",
            getDeliveryTiming: getOutValue(dummyStart, current),
            laterDeliveryTiming: getOutValue(start, current),
            branchForArea: {
              id: key,
              english: eng,
              arabic: arab,
              end:
                temp?.office_end_time == "00:00:00"
                  ? moment("23:59:59", "HH:mm:ss")
                  : moment(temp?.office_end_time, "HH:mm:ss"),
              start: moment(temp?.office_start_time, "HH:mm:ss"),
            },
          };
          return res;
        }
      } else {
        setLoading(false);
        return {};
      }
    }
  };

  const getWithinValue = (end, lng) => {
    if (vendorSlug !== "alawael-bilingual-school") {
      return lng ? `2 ساعات` : "2 Hours";
    } else {
      return moment(new Date(end)).locale("en").format("hh:mm A");
    }
  };
  const getOutValue = (start, current) => {
    if (vendorSlug !== "alawael-bilingual-school") {
      if (start > current) {
        const value = new Date(start.setHours(start.getHours() + 2));
        return value;
      } else {
        const value = new Date(start.setDate(start.getDate() + 1));
        return value;
      }
    } else {
      if (start > current) {
        const value = new Date(start.setMinutes(start.getMinutes() + 30));
        return value;
      } else {
        const value = new Date(start.setDate(start.getDate() + 1));
        return value;
      }
    }
  };

  /**
   * @desc Callback function for resize event which sets width state to the innerWidth of screen
   */
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    if ("caches" in window) {
      clearCache();
    }
  }, []);

  const clearCache = async () => {
    // window.location.reload(true);
    const keys = await caches.keys();
    await Promise.all(keys.map((key) => caches.delete(key)));
  };

  /**
   * @desc useEffect to add an event listener for resize
   * @returns {function} to remove the resize event listener
   */
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setLoading((loading) => true);
    if (!localStorage.getItem("userID")) {
      let result = "";
      let characters = "abcdefghijklmnopqrstuvwxyz0123456789";
      let charactersLength = characters.length;
      for (var i = 0; i < 10; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      localStorage.setItem("userID", result);
    }
    handleVendorData();
  }, []);

  const handleVendorData = async () => {
    const response = await getVendorSlug({
      host: window.location.host.replace(/^www\./, ""),
    });
    // const response = await getVendorSlug({
    //   host: "estore.payzah.support/dev",
    // });
    if (response?.status) {
      setDetails((d) => ({
        vendor: {
          ...response.data.vendor_data,
          slug_type: response.data.slug_type,
        },
      }));
      if (response.data.vendor_data.vendors_id === "1250") {
        setAreaDetails((areaDetails) => ({
          ...areaDetails,
          area_id: 1,
          area: "Abdullah Al Salem",
          ar_area: "عبد الله السالم",
        }));
      }
      localStorage.setItem("vendorSlug", response.data.ecom_url_slug);
      setVendorSlug((slug) => response.data.ecom_url_slug);
      if (!sessionStorage.getItem("language")) {
        setLanguage(
          response?.data?.vendor_data?.default_language == 1 ? "rtl" : "ltr"
        );
        sessionStorage.setItem(
          "language",
          response?.data?.vendor_data?.default_language == 1 ? "rtl" : "ltr"
        );
        document
          .getElementsByTagName("html")[0]
          .setAttribute(
            "dir",
            response?.data?.vendor_data?.default_language == 1 ? "rtl" : "ltr"
          );
      }
      const favicon = document.getElementById("favicon");
      const appleFavicon = document.getElementById("apple-favicon");
      document
        .getElementsByTagName("meta")
        .namedItem("description")
        .setAttribute("content", response?.data?.vendor_data?.slogan);
      favicon.href = response?.data?.vendor_data?.english_new_background;
      appleFavicon.href = response?.data?.vendor_data?.english_new_background;
      document.title = response?.data?.vendor_data?.name;

      let light = lightOrDark(response.data.vendor_data.vendor_color);
      let hover = LightenDarkenColor(
        response.data.vendor_data.vendor_color,
        light ? -30 : 50
      );
      document.documentElement.style.setProperty("--vendor-hover-color", hover);
      document.documentElement.style.setProperty(
        "--vendor-color",
        response.data.vendor_data.vendor_color
      );
      document.documentElement.style.setProperty(
        "--vendor-color-light",
        response.data.vendor_data.vendor_color + "99"
      );
      document.documentElement.style.setProperty(
        "--vendor-font-family",
        "'SFT Schrifted Sans TRIAL Var'"
      );

      if (response.data.ecom_url_slug == "achar-chefna") {
        let script = document.createElement("meta");
        script.name = `facebook-domain-verification`;
        script.content = "yki4zun6ju6uu8znt9jozytsla19yl";
        let head = document.getElementById("head");
        head.appendChild(script);
      }
      if (response.data.ecom_url_slug == "mijana-restaurant-and-café") {
        let script = document.createElement("meta");
        script.name = `facebook-domain-verification`;
        script.content = "t00z8vtv45dtep9934vupbht36w507";
        let head = document.getElementById("head");
        head.appendChild(script);
      }
      if (response.data.vendor_data.fb_verification) {
        let script = document.createElement("meta");
        script.name = `facebook-domain-verification`;
        script.content = response.data.vendor_data.fb_verification;
        let head = document.getElementById("head");
        head.appendChild(script);
      }
      if (response.data.ecom_url_slug == "alawael-bilingual-school") {
        setAreaDetails({ ...areaDetails, type: "pickup" });
      }
      if (localStorage?.getItem("check") == 1 && !location.includes("order=")) {
        localStorage?.removeItem("check");
      }

      //Static for Spread butters

      if (response.data.ecom_url_slug == "butters") {
        const script = document.createElement("script");
        script.async = true;
        script.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '546180060531909');
      fbq('track', 'PageView');
    `;
        document.head.appendChild(script);

        const noscript = document.createElement("noscript");
        noscript.innerHTML = `<img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=546180060531909&ev=PageView&noscript=1" alt="Facebook Pixel" />`;
        document.body.appendChild(noscript);
      }

      if (response.data.ecom_url_slug == "mijana-restaurant-and-café") {
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, "script", "dataLayer", "GTM-WVBNCFT");
        const GTMnoScript = `<iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-WVBNCFT"
            height="0"
            width="0"
            style={{
              display: "none",
              visibility: "hidden",
            }}
          ></iframe>`;
        const nposcriptEle = document.createElement("noscript");
        const noinlinecode = document.createTextNode(
          `<iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-WVBNCFT"
            height="0"
            width="0"
            style={{
              display: "none",
              visibility: "hidden",
            }}
          ></iframe>`
        );
        nposcriptEle.appendChild(noinlinecode);
        document.body.appendChild(nposcriptEle);
      }

      if (
        response?.data?.vendor_data?.fb_pixel_code &&
        response?.data?.vendor_data?.fb_pixel_code != ""
      ) {
        ReactPixel.init(
          response?.data?.vendor_data?.fb_pixel_code,
          {},
          { autoConfig: true, debug: false }
        );
        ReactPixel.pageView();
        const time = Date.now();
        const sec = Math.round(time / 1000);
        // static server api for facebook pixel api for mijana client
        if (response.data.ecom_url_slug == "mijana-restaurant-and-café") {
          setPageView({
            fb_pixel_code: response?.data?.vendor_data?.fb_pixel_code,
            fb_access_token:
              "EAAGZA8GMZAs1IBAC9mDImnZCTAdafRzN769x6ZCIRMExueSZBZBfnDkIzGrsP4gZBMZCCwXaSvKNggZBEKdEk3582JWiwecrnZAEHFzfCaYKSNRbltxMm2cSvUrgZBUDpVNZCQAOVWUuzO4m7nbvQn1Wqb94IBbVSexSbwWzAf6TYV80HQF1ZAZAzGcXKB",
            support_mail: response?.data?.vendor_data?.support_mail,
          });
        }

        //Dynamic facebook access token
        if (
          response?.data?.vendor_data?.fb_access_token &&
          response?.data?.vendor_data?.fb_access_token != ""
        ) {
          setPageView({
            fb_pixel_code: response?.data?.vendor_data?.fb_pixel_code,
            fb_access_token: response?.data?.vendor_data?.fb_access_token,
            support_mail: response?.data?.vendor_data?.support_mail,
          });
        }
      }
      if (response?.data?.vendor_data?.vendors_id === "132") {
        TiktokPixel.init("CAGSMQ3C77UEV29MP3G0");
      }
      if (
        response?.data?.vendor_data?.snap_pixel_code &&
        response?.data?.vendor_data?.snap_pixel_code != ""
      ) {
        SnapPixel.init(
          response?.data?.vendor_data?.snap_pixel_code,
          { user_email: response?.data?.support_mail },
          { autoConfig: true, debug: false }
        );
      }
      if (
        response?.data?.vendor_data?.google_tag_code &&
        response?.data?.vendor_data?.google_tag_code != ""
      ) {
        //checks if the google is a tag manager code or a analytics code, and call the code accordingly
        if (/^GTM/.test(response?.data?.vendor_data?.google_tag_code))
          (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
              "gtm.start": new Date().getTime(),
              event: "gtm.js",
            });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != "dataLayer" ? "&l=" + l : "";
            j.async = true;
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
            f.parentNode.insertBefore(j, f);
          })(
            window,
            document,
            "script",
            "dataLayer",
            response?.data?.vendor_data?.google_tag_code
          );
        else {
          let script = document.createElement("script");
          script.src = `https://www.googletagmanager.com/gtag/js?id=${response?.data?.vendor_data?.google_tag_code}`;
          script.async = true;
          let head = document.getElementById("root");
          head.appendChild(script);
          window.googleTag = response?.data?.vendor_data?.google_tag_code;

          googleinit();
        }
      }
    } else {
      setNoVendor(false);
    }
  };

  const [details, setDetails] = useState(null);

  useEffect(() => {
    if (details?.vendor?.fb_pixel_code) {
      ReactPixel.init(
        details?.vendor?.fb_pixel_code,
        {},
        { autoConfig: true, debug: false }
      );
      ReactPixel.pageView();

      //static for mijana
      if (vendorSlug == "mijana-restaurant-and-café") {
        setPageView({
          fb_pixel_code: details?.vendor?.fb_pixel_code,
          fb_access_token:
            "EAAGZA8GMZAs1IBAC9mDImnZCTAdafRzN769x6ZCIRMExueSZBZBfnDkIzGrsP4gZBMZCCwXaSvKNggZBEKdEk3582JWiwecrnZAEHFzfCaYKSNRbltxMm2cSvUrgZBUDpVNZCQAOVWUuzO4m7nbvQn1Wqb94IBbVSexSbwWzAf6TYV80HQF1ZAZAzGcXKB",
          support_mail: details?.vendor?.support_mail,
        });
      }

      // dynamic for server api
      if (
        details?.vendor?.fb_access_token &&
        details?.vendor?.fb_access_token != ""
      ) {
        setPageView({
          fb_pixel_code: details?.vendor?.fb_pixel_code,
          fb_access_token: details?.vendor?.fb_access_token,
          support_mail: details?.vendor?.support_mail,
        });
      }
    }
  }, [details?.vendor?.fb_pixel_code]);

  /**
   * @desc useEffect contains axios calls to get the homepage details for both estore and booking according to the vendor type
   *
   */

  const handleAllCategory = async () => {
    const response = await getAllCategoryAndProducts({
      vendorSlug: vendorSlug,
      vendor_id: details?.vendor?.vendors_id,
      area_id: areaDetails?.area_id,
    });
    if (response?.status) {
      if (
        details?.vendor?.home_page_type == 10 ||
        details?.vendor?.home_page_type == 13 ||
        details?.vendor?.home_page_type == 16
      ) {
        setDetails((details) => ({
          ...details,
          categories: response.data.categories,
        }));
      } else {
        setDetails((details) => ({
          ...details,
          categories: response.data.categories.filter(
            (category) => category.products.length != 0
          ),
        }));
      }
      setLoading((loading) => false);
      // setTimeout(() => setLoading((loading) => false), 500);
    } else {
      console.log(response);
    }
  };
  const handleBookAPI = async () => {
    const response = await getBookingHomepage({
      vendorSlug: vendorSlug,
      booking_vendor_id: details?.vendor?.booking_vendor_id,
    });
    if (response?.status) {
      if (
        details?.vendor?.home_page_type == 2 ||
        details?.vendor?.home_page_type == 3 ||
        details?.vendor?.home_page_type == 4
      ) {
        let temp = Object.values(response.data.week_days)?.map((days) => ({
          ...days,
          name_ar: moment(days.id % 7, "d")
            ?.locale("ar")
            ?.format("dddd"),
        }));
        temp?.unshift(temp?.pop());
        setBookingSets((sets) => ({
          ...sets,
          ...response.data,
          week_days: temp,
          morning: response.data.search_slot?.filter(
            (time) => time.slot_start_time < "12:00:00"
          ),
          evening: response.data.search_slot?.filter(
            (time) => time.slot_start_time >= "12:00:00"
          ),
          booking_month:
            details.vendor.booking_vendor_id === "1"
              ? Object.values(response?.data?.booking_month)?.slice(0, -1)
              : response.data.booking_month,
        }));
      } else {
        setBookingSets((set) => ({ ...set, ...response.data }));
      }
      setLoading((loading) => false);
      // setTimeout(() => setLoading((loading) => false), 500);
    } else {
      console.log(response);
    }
  };
  useEffect(() => {
    if (vendorSlug !== "") {
      if (details?.vendor?.slug_type !== "2") {
        handleAllCategory();
      } else {
        handleBookAPI();
      }
    }
  }, [vendorSlug, areaDetails?.area_id]);

  const handleBookingBranches = async () => {
    const response = await getBookingBranches({
      vendorSlug: vendorSlug,
      booking_vendor_id: details?.vendor?.booking_vendor_id,
    });
    if (response?.status) {
      setAreaDetails((areaDetails) => ({
        ...areaDetails,
        data: { ...areaDetails.data, branch: response.data.location },
      }));
    }
  };

  /**
   * @desc useEffect to add interval to recall the area details using loadArea() for estore only
   *
   */
  useEffect(async () => {
    if (details?.vendor?.slug_type && details?.vendor?.slug_type != 2) {
      loadArea();
      const interval = setInterval(() => loadArea(), 600000);
      return () => clearInterval(interval);
    }
  }, [details]);

  /**
   * @desc useEffect contains axios calls to get the branch details for booking vendor and set it to areaDetails state
   *
   */
  useEffect(async () => {
    if (details?.vendor?.slug_type == 2) {
      handleBookingBranches();
    }
  }, [details]);

  /**
   * @desc useEffect to create PWA manifest for the vendor using it's design details and assign it to the manifest tag's href
   *
   */
  useEffect(() => {
    if (details?.vendor) {
      let dyna = {
        short_name: details?.vendor?.name,
        name: details?.vendor?.name,
        icons: [
          {
            src: details?.vendor?.english_new_background,
            type: "image/png",
            sizes: "1080x1080",
          },
        ],
        start_url: window.location.origin,
        display: "standalone",
        theme_color: details?.vendor?.vendor_color,
        background_color: details?.vendor?.vendor_color,
      };
      const stringManifest = JSON.stringify(dyna);
      const blob = new Blob([stringManifest], { type: "application/json" });
      const manifestURL = URL.createObjectURL(blob);
      $("#manifest").attr("href", manifestURL);
    }
  }, [details?.vendor]);

  /**
   * @desc useEffect to check if the user had added a cart in previous session. If yes then checks how old the cart is
   *       and gets it or emptys it. Currently the cart resets if it's older than 1 hour.
   */

  const handleEmptyCart = async () => {
    const response = await emptyUserCart({
      vendorSlug: vendorSlug,
      vendor_id: details?.vendor?.vendors_id,
      user_string: localStorage.getItem("userID"),
    });
    if (response?.status) {
      if (!response.data?.cartCount == 0) {
        setCart(response.data);
      }
    }
  };

  const handleUserCart = async () => {
    const response = await getUserCart({
      vendorSlug: vendorSlug,
      vendor_id: details?.vendor?.vendors_id,
      user_string: localStorage.getItem("userID"),
      area_id: areaDetails?.area_id,
    });
    if (response?.status) {
      if (!response.data?.cartCount == 0) {
        setCart(response.data);
      }
    }
  };
  useEffect(() => {
    if (localStorage.getItem("cartTime")) {
      let prevTime = Date.parse(localStorage.getItem("cartTime"));
      let nowTime = new Date();
      let diff = Math.abs(prevTime - nowTime) / 3600000;
      if (diff > 2) {
        if (localStorage.getItem("userID")) {
          handleEmptyCart();
        }
      } else {
        if (localStorage.getItem("userID")) {
          handleUserCart();
        }
      }
    }
  }, [details, areaDetails?.area_id]);
  /**
   * @desc useEffect to set the direction of html according to language
   */
  useEffect(() => {
    document.getElementsByTagName("html")[0].setAttribute("dir", language);
  }, []);

  const location = window.location.pathname;

  useEffect(() => {
    if (
      details &&
      details.vendor &&
      details.vendor.international_delivery !== "3" &&
      details.vendor.international_delivery !== ""
    ) {
      getUserCountry();
    }
  }, [details?.vendor?.vendors_id]);

  return (
    <React.Fragment>
      {noVendor ? (
        <>
          {loading == false ||
          location.includes("order=") ||
          location.includes("booked=") ? (
            <LanguageContext.Provider value={{ language, setLanguage }}>
              <VendorContext.Provider value={details}>
                <UserContext.Provider value={{ userDetails, setUserDetails }}>
                  <VendorSlugContext.Provider value={vendorSlug}>
                    <SearchContext.Provider value={{ search, setSearch }}>
                      <CartContext.Provider value={{ cart, setCart }}>
                        <BookingSetContext.Provider
                          value={{ bookingSets, setBookingSets }}
                        >
                          <AvaibilityContext.Provider
                            value={{ avaibility, setAvaibility }}
                          >
                            <AreaContext.Provider
                              value={{ areaDetails, setAreaDetails }}
                            >
                              <InterNationalContext.Provider
                                value={{
                                  internationalDelivery,
                                  setInternationalDelivery,
                                }}
                              >
                                <ContactContext.Provider
                                  value={{
                                    addressDetails,
                                    setAddressDetails,
                                    contactDetails,
                                    setContactDetails,
                                    payment,
                                    setPayment,
                                  }}
                                >
                                  <Router /* basename="/beta" */>
                                    <React.Fragment>
                                      <ScrollToTop />
                                      {details?.vendor_data?.ecom_url_slug ==
                                        "mijana-restaurant-and-café" && (
                                        <noscript>
                                          <iframe
                                            src="https://www.googletagmanager.com/ns.html?id=GTM-WVBNCFT"
                                            height="0"
                                            width="0"
                                            style={{
                                              display: "none",
                                              visibility: "hidden",
                                            }}
                                          ></iframe>
                                        </noscript>
                                      )}
                                      {details?.vendor_data?.google_tag_code !=
                                        "" && (
                                        <noscript>
                                          <iframe
                                            src={`https://www.googletagmanager.com/ns.html?id=${details?.vendor_data?.google_tag_code}`}
                                            style={{
                                              display: "none",
                                              visibility: "hidden",
                                              width: 0,
                                              height: 0,
                                            }}
                                          ></iframe>
                                        </noscript>
                                      )}
                                      <Switch>
                                        <Route exact path={`/`}>
                                          {details?.vendor?.slug_type != 2 ? (
                                            <>
                                              {width < 992 && (
                                                <AppInstall
                                                  installable={installable}
                                                  width={width}
                                                  setInstallable={
                                                    setInstallable
                                                  }
                                                  handleInstallClick={
                                                    handleInstallClick
                                                  }
                                                ></AppInstall>
                                              )}
                                              <HomePage
                                                installable={installable}
                                                width={width}
                                                toggleView={toggleView}
                                                setToggleView={setToggleView}
                                                loading={loading}
                                              />
                                            </>
                                          ) : (
                                            <Booking
                                              installable={installable}
                                              width={width}
                                              setInstallable={setInstallable}
                                              handleInstallClick={
                                                handleInstallClick
                                              }
                                            ></Booking>
                                          )}
                                        </Route>
                                        <Route exact path={`/area`}>
                                          <>
                                            <LayoutHolder
                                              isnavbar={true}
                                              component={<NewOrderMode />}
                                            />
                                          </>
                                        </Route>
                                        <Route exact path={`/timing`}>
                                          <LayoutHolder
                                            isnavbar={true}
                                            component={<DeliveryTimePage />}
                                          />
                                        </Route>
                                        <Route exact path={`/branches`}>
                                          <LayoutHolder
                                            isnavbar={true}
                                            component={<BranchListPage />}
                                          />
                                        </Route>
                                        <Route
                                          exact
                                          path={`/products=:category`}
                                        >
                                          <LayoutHolder
                                            isnavbar={true}
                                            component={<NewCategoeyModal />}
                                          />
                                        </Route>
                                        <Route exact path={`/review`}>
                                          <LayoutHolder
                                            isnavbar={true}
                                            component={
                                              <NewReviewOrderPage></NewReviewOrderPage>
                                            }
                                          />
                                        </Route>
                                        <Route exact path={`/product=:id`}>
                                          <LayoutHolder
                                            isnavbar={true}
                                            component={<ProductPage />}
                                          />
                                        </Route>
                                        <Route exact path={`/search`}>
                                          <LayoutHolder
                                            isnavbar={true}
                                            component={<SearchPage />}
                                          />
                                        </Route>
                                        <Route exact path={`/branch=:id`}>
                                          <LayoutHolder
                                            isnavbar={true}
                                            component={
                                              <BranchPage></BranchPage>
                                            }
                                          />
                                        </Route>
                                        <Route exact path="/contact-details">
                                          <LayoutHolder
                                            isnavbar={true}
                                            component={
                                              <NewCheckOutPage
                                                installable={installable}
                                                width={width}
                                              />
                                            }
                                          />
                                        </Route>
                                        <Route exact path={`/checkout`}>
                                          <LayoutHolder
                                            isnavbar={false}
                                            component={
                                              <NewOrderDetailsPage
                                                width={width}
                                              />
                                            }
                                          />
                                        </Route>
                                        <Route exact path={`/delivery-address`}>
                                          <LayoutHolder
                                            isnavbar={true}
                                            component={<NewDeliveryAddress />}
                                          />
                                        </Route>
                                        <Route exact path={`/order=:id`}>
                                          <LayoutHolder
                                            isnavbar={true}
                                            component={<NewOrderStatuPage />}
                                          />
                                        </Route>
                                        <Route exact path={`/track-order`}>
                                          <LayoutHolder
                                            isnavbar={true}
                                            component={
                                              <TrackOrder></TrackOrder>
                                            }
                                          />
                                        </Route>
                                        <Route exact path={`/booked=:id`}>
                                          <LayoutHolder
                                            isnavbar={true}
                                            component={
                                              <BookingConfirm></BookingConfirm>
                                            }
                                          />
                                        </Route>
                                        {/* <Route exact path={'/apple-pay'}>
                                          <LayoutHolder
                                            isnavbar={true}
                                            component={
                                              <ApplePay />
                                            }
                                          />
                                        </Route> */}
                                        <Route path="*">
                                          <Redirect to={`/`}></Redirect>
                                        </Route>
                                      </Switch>
                                    </React.Fragment>
                                  </Router>
                                  <ToastContainer
                                    position={
                                      language == "ltr"
                                        ? "top-right"
                                        : "top-left"
                                    }
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={language == "ltr" ? false : true}
                                    pauseOnFocusLoss
                                    draggable={false}
                                    pauseOnHover
                                  />
                                </ContactContext.Provider>
                              </InterNationalContext.Provider>
                            </AreaContext.Provider>
                          </AvaibilityContext.Provider>
                        </BookingSetContext.Provider>
                      </CartContext.Provider>
                    </SearchContext.Provider>
                  </VendorSlugContext.Provider>
                </UserContext.Provider>
              </VendorContext.Provider>
            </LanguageContext.Provider>
          ) : (
            <div
              style={{
                position: "fixed",
                width: "100%",
                top: 0,
                left: 0,
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                background: "white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  background: details?.vendor?.vendor_color
                    ? details?.vendor?.vendor_color
                    : "white",
                  opacity: details?.vendor?.english_new_background ? 1 : 0,
                  padding: "50px",
                  borderRadius: 10,
                }}
                className="load-img"
              >
                <img
                  src={
                    language == "ltr"
                      ? details?.vendor?.english_new_background
                      : details?.vendor?.arabic_new_background
                  }
                  style={{
                    height: 150,
                    minWidth: "150px",
                    width: 150,
                    borderRadius: 10,
                    objectFit: "cover",
                    marginBottom: 15,
                  }}
                  loading="lazy"
                ></img>
                <BarLoader color="white" width="140px" height={4}></BarLoader>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div style={{ height: "99vh", width: "100%", position: "relative" }}>
            <img
              src="pictures/underConstruction.png"
              className="vendor-const"
            />
          </div>
        </>
      )}
    </React.Fragment>
  );
}

export default App;
